@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'Poppins' !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.2px;

	}
}

input:disabled{
	background-color:white;
	color: #A0AEC0 !important;
	cursor: auto;
}

input[type="radio"]:disabled ~ span{
	color: #A0AEC0 
}

input[type="radio"]:disabled ~ div{	
	border-color: #718096 !important; 
}

select:disabled {
	background-color:white;
	color: #A0AEC0	;
	border-color: #E2E8F0 !important; 
}



.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  width: 60px;
  height: 60px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 45px;
  height: 45px;
  margin: 8px;
  border: 4px solid #3696B2;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3696B2 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input.defaultCheckbox{
	 color: white; 
} 

input.defaultCheckbox::before {
	content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.66662 10.115L12.7946 3.98633L13.7379 4.92899L6.66662 12.0003L2.42395 7.75766L3.36662 6.81499L6.66662 10.115Z' fill='white'/%3E%3C/svg%3E%0A"); 
	fill: currentColor;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	top:0;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input.defaultCheckbox::before path {
	fill: currentColor; 
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}

.markdown p {
	margin-top: 20px;
}

@keyframes preload {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@keyframes move {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: 20px 20px;
	}
}

@keyframes vanish {
	0% {
		opacity: 1;
	}

	90%{
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fadeInOut {
	0% {
		opacity: 0;
	}
	25% {
		opacity: 1;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.upload-bar--vanish{
	animation: vanish 2s ease-in-out forwards;
}

.upload-file--preload{
	animation: preload 2s ease-in-out;
}

.upload-file--loading{
	background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.7) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.8) 75%, transparent 75%, transparent);
    background-size: 20px 20px;
	animation: move 2s linear infinite;
}

.icon-verified__done{
	animation: fadeInOut 2s linear; 
}

.multistep-input::placeholder{
	opacity: 0;
}

.multistep-phone-focus{
	margin: 15px 0;
}

.multistep-input:not(:placeholder-shown) ~ label,
.multistep-input:focus ~ label,
.multistep-input-focus > label{
	top: -9px;
	font-size: 12px;
	line-height: 1;
}

.multistep-phone-focus > label{
	top: -9px;
	font-size: 12px;
	background: white;
	z-index: 11;
	line-height: 1;
}

.multistep-phone-focus .react-tel-input .selected-flag:hover, 
.multistep-phone-focus .react-tel-input .selected-flag:focus{
	background: transparent;
}

.multistep-phone-focus .react-tel-input .flag-dropdown.open .selected-flag{
	background: transparent;
	border-radius: 10px;
}

@media screen and (min-width: 991px){
	.multistep-phone-focus{
		margin: 0;
	}

	.multistep-phone-focus > label{
		top: 9px;
	}
}

.multistep-checkbox:before {
	content: "";
	position: absolute; 
	height: 10px; 
	width: 6px; 
	border-bottom: 3px solid white; 
	border-right: 3px solid white;
	transform: rotate(37deg); 
	margin: 6px;
	top: -4px;
	left: -1px;
} 

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.field-container-text.error,
.field-container-date.error,
.field-container-phone.error .react-tel-input,
.field-container-select.error{
	border-color: tomato !important;
}

.field-container-phone .react-tel-input .country-list{
	bottom: 50px;
}

.field-container-text.error label,
.field-container-date.error label,
.field-container-phone.error label,
.field-container-radio.error,
.field-container-select.error label,
.field-container-checkbox.error{
	color: tomato;
}

.field-container-checkbox.error input{
	border-color: tomato;
}

.field-container-select .react-dropdown-select-content{
	background: white;
}

.field-container-select .react-dropdown-select-dropdown{
	width: 100%;
	z-index: 99;
}

.field-container-select .react-dropdown-select-dropdown-position-bottom{
	top: 43px;
}

.field-container-select .react-dropdown-select,
.field-container-select .react-dropdown-select:focus{
	border: 0;
	padding: 0;
	outline: none !important;
	box-shadow: none !important;
}

.field-container-select .react-dropdown-select svg{
	bottom: 2px;
    position: relative;
    left: 3px;
	width: 20px;
	height: 20px;
}

.field-container-select .react-dropdown-select-type-single input{
	font-size: 16px;
}

@keyframes pulse{
	0%{
		background: #F4F4F4;
		color: #F4F4F4;
	}
	50%{
		background: #E6E6E8;
		color: #E6E6E8;
	}
	100%{
		background: #F4F4F4;
		color: #F4F4F4;
	}
}

.skeleton-animation{
	pointer-events: none;
}

.skeleton-animation .skeleton-pulse{
	border-radius: 5px;
	animation: pulse 2s infinite;
	min-width: 200px;
	min-height: 24px;
}

.skeleton-animation .skeleton-opacity{
	opacity: 0;
}

.nested-select.reset-value .react-dropdown-select-content span{
	position: absolute;
	opacity: 0;
}