.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay{
    background: #390094;
    border-color: #390094;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)){
    box-shadow: none;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange,
.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange{
    border-radius: 100px;
}

.flatpickr-day.inRange{
    background: #EAE5F3;
    box-shadow: 0px 0 0 #EAE5F3, 0 0 0 #EAE5F3;
    
}

.flatpickr-day.selected.startRange:before, .flatpickr-day.startRange.startRange:before, .flatpickr-day.endRange.startRange:before,
.flatpickr-day.selected.endRange:before, .flatpickr-day.startRange.endRange:before, .flatpickr-day.endRange.endRange:before{
    content: "";
    position: absolute;
    display: block;
    top: -1px;
    background: #EAE5F3;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: -1;
}

.flatpickr-day.selected.startRange:before, .flatpickr-day.startRange.startRange:before, .flatpickr-day.endRange.startRange:before{
    left: 1px;
    border-radius: 50px 0 0 50px;
}

.flatpickr-calendar.arrowTop:after {
    pointer-events: none;
    user-select: none;
    opacity: none;
    cursor: default;
    display: none;
}

.flatpickr-calendar.arrowTop::before {
    pointer-events: none;
    user-select: none;
    opacity: none;
    cursor: default;
    display: none;
}

.flatpickr-day.selected.endRange:before, .flatpickr-day.startRange.endRange:before, .flatpickr-day.endRange.endRange:before{
    right: 1px;
    border-radius: 0 50px 50px 0;
}

.flatpickr-calendar{
    box-sizing: border-box;
    width: 688px;
    height: 450px;
    border-radius: 30px;
    top: 287px !important; 
    right: 70px !important;
    
}

.flatpickr-day:nth-child(7n + 1) {
    border-radius: 50px 0 0 50px;
}

  
.flatpickr-day:nth-child(7n) {
    border-radius: 0 50px 50px 0;
}

.flatpickr-days .dayContainer{
    box-sizing: border-box;
    width: 36px;
    font-size: 14px;
    margin-top: 12px;
}

.flatpickr-weekdaycontainer {
    padding-left: 24px;
    padding-right: 24px;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: 36px;
}

.dayContainer {
    padding-left: 24px;
    padding-right: 24px;
}

.flatpickr-month {
    margin-top: 32px;
    color: #390094;
}

.cur-month {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600 !important; 
    line-height: 24px;
}

.flatpickr-current-month span.cur-month:hover {
    background: transparent;
}

.arrowUp{
    display: none !important;
}

.arrowDown {
    display: none !important;
}

.cur-year {
    pointer-events: none;
    user-select: none;
    opacity: none;
    cursor: default;
    display: none;
    font-weight: 600 !important; 
}

.numInputWrapper:hover {
    background-color: transparent;
}

.numInputWrapper {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
}

.flatpickr-weekdays {
    margin-top: 13px;
}

.flatpickr-prev-month {
    margin-top: 35px;
    margin-left: 48px;
    width: 24px;
    height: 24px;
}

.flatpickr-next-month {
    margin-top: 35px;
    margin-right: 58px;
    width: 24px;
    height: 24px;
}

.flatpickr-rContainer {
    padding: 0px;
}

.flatpickr-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding: 20px 24px;
    margin-top: 10px;
  }
  
  .flatpickr-cancel-button {
    background: transparent;
    border: 1px solid #390094;
    border-radius: 33px;
    color: #390094;
    cursor: pointer;
    width: auto;
    min-width: 133px;
    height: 36px;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 600;
    padding: 4px 16px;
    transition: background-color 0.2s;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

.flatpickr-ok-button {
    background: #390094;
    border: none;
    border-radius: 33px;
    color: white;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    width: 73px;
    height: 36px;
    padding: 4px 24px;
    transition: background-color 0.2s;
}

.flatpickr-ok-button:hover {
    background: #2d0070;
}
  
.flatpickr-ok-button-disabled {
    background: #cbd5e0;
    border: none;
    border-radius: 33px;
    color: #718096;
    cursor: not-allowed;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    width: 73px;
    height: 36px;
    padding: 4px 24px;
    opacity: 0.7;
  }
